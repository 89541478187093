<script>
import TextInput from "../forms/TextInput";
import EmailFormSection from "./EmailFormSection";

export default {
  components: {
    EmailFormSection,
    TextInput,
  },
  props: {
    requestPending: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
    },
  },
  data: () => ({
    email: "",
    emailError: "",
    password: "",
    passwordError: "",
    rememberMe: false,
    formSubmitted: false,
      loginFormMessage: '',
  }),
  computed: {
    isFormInvalid() {
      return this.emailError || this.passwordError;
    },
    requiredFieldError() {
      return this.$globalTexts?.global__forms__requiredfielderror || "";
    },
    emailLabel() {
      return this.$globalTexts?.login__email_label || "";
    },
    passwordLabel() {
      return this.$globalTexts?.login__password_label || "";
    },
    loginButtonLabel() {
      return this.$globalTexts?.login__submit || "";
    },
    forgotPasswordPageUrl() {
      return this.$route.pathname + "?action=forgot-password";
    },
  },
  emits: ["login"],
  methods: {
    emailFocused() {
      this.emailError = "";
      this.passwordFocused();
    },
    emailChanged(formSection) {
      this.email = formSection.email;
      this.emailError = formSection.emailError;
    },
    passwordFocused() {
      this.passwordError = "";
      this.resetFormSubmissionState();
    },
    passwordChanged() {
      if (!this.password) {
        this.passwordError = this.requiredFieldError;
      } else {
        this.passwordError = "";
      }
    },
    resetFormSubmissionState() {
      this.formSubmitted = false;
    },
    updateFormState() {
      this.$refs.email.updateFormState();
      this.passwordChanged();
    },
    onEnterKeyPressed() {
      this.onLoginClicked();
    },
    onLoginClicked() {
      this.resetFormSubmissionState();
      this.updateFormState();

      if (this.isFormInvalid) {
        return;
      }

      this.$emit("login", {
        email: this.email,
        password: this.password,
      });

      this.formSubmitted = true;
    }
  }
};
</script>

<template>
  <div>
    <div class="login-form__message" v-if="loginFormMessage">{{loginFormMessage}}</div>

    <div class="login-form">
      <div class="login-form__wrapper">
        <email-form-section
          ref="email"
          row-css-class="login-form__row"
          row-label-css-class="login-form__label"
          :email-label="emailLabel"
          email-label-for="loginEmail"
          @input-focused="emailFocused"
          @email-changed="emailChanged"
          @enter-key-pressed="onEnterKeyPressed"
        />

        <div class="login-form__row">
          <text-input
            id="loginPassword"
            :label="passwordLabel"
            v-model="password"
            type="password"
            :error-message="passwordError"
            @focus="passwordFocused"
            @blur="passwordChanged"
            @enter-key-pressed="onEnterKeyPressed"
          />
        </div>

        <router-link
          :to="forgotPasswordPageUrl"
          class="small login-form__forgot-password-link"
        >
          {{$globalTexts?.login__forgot_link_text}}
        </router-link>

        <div class="login-form__row">
          <button
            class="primary-button login-form__button"
            :disabled="requestPending"
            @mousedown.prevent
            @click="
              $event.target.focus();
              onLoginClicked();
            "
          >
            {{ loginButtonLabel }}
          </button>
        </div>

        <div v-if="formSubmitted && error" class="login-form__error">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.login-form {
  display: flex;
  justify-content: center;
}

.login-form__message {
  text-align: center;
    width: 100%;
    padding-top: 14px;
}

.login-form__wrapper {
  width: 100%;
}

.login-form__row {
  margin-top: 1.5rem;
}

.login-form__row--inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-form__forgot-password-link {
  display: block;
  margin-top: 0.5rem;
}

.login-form__label {
  font-size: 18px;
  margin-bottom: 0.25rem;
}

.login-form__error {
  color: var(--color-feedback-error);
  text-align: center;
  font-size: 12px;
  line-height: 22px;
  font-weight: 600;
}

.login-form__link {
  outline: none;
}

.login-form__button {
  width: 100%;
  margin-left: 0;
}
</style>
