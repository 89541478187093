<script>
import TextInput from "../forms/TextInput";

export default {
  components: { TextInput },
  props: {
    sectionCssClass: {
      type: String,
      default: "",
    },
    rowCssClass: {
      type: String,
      default: "",
    },
    rowLabelCssClass: {
      type: String,
      default: "",
    },
    emailLabel: {
      type: String,
      default: "",
    },
    emailLabelFor: {
      type: String,
      default: "email",
    },
  },
  data: () => ({
    email: "",
    emailError: "",
  }),
  computed: {
    requiredFieldError() {
      return this.$globalTexts?.global__forms__requiredfielderror || "";
    }
  },
  methods: {
    onEmailFocused() {
      this.emailError = "";
      this.$emit("input-focused");
    },
    onEmailChanged() {
      if (!this.email) {
        this.emailError = this.requiredFieldError;
      } else {
        this.emailError = "";
      }
      this.emitEmailChanged();
    },
    setPristine() {
      this.onEmailFocused();
    },
    updateFormState() {
      this.onEmailChanged();
    },
    clear() {
      this.email = "";
      this.setPristine();
    },
    emitEmailChanged() {
      this.$emit("email-changed", {
        email: this.email,
        emailError: this.emailError,
      });
    },
  },
};
</script>

<template>
  <div :class="[sectionCssClass]">
    <div :class="[rowCssClass]">
      <text-input
        :id="emailLabelFor"
        :label="emailLabel"
        v-model="email"
        type="email"
        :error-message="emailError"
        @focus="onEmailFocused"
        @blur="onEmailChanged"
        @enter-key-pressed="$emit('enter-key-pressed')"
      />
    </div>
  </div>
</template>
