<script>
import { navigateKey } from "@drapejs/core";
import TextInput from "../forms/TextInput.vue";
import ConfirmPasswordFormSection from "./ConfirmPasswordFormSection.vue";

export default {
  components: {
    ConfirmPasswordFormSection,
    TextInput,
  },
  props: {
    requestPending: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
    },
  },
  inject: {
    validateResetPasswordCode: "validateResetPasswordCode",
    navigate: navigateKey,
  },
  data: () => ({
    password: "",
    passwordError: "",
    formSubmitted: false,
    init: false,
    isInvalidResetCode: false,
  }),
  emits: ["reset-password"],
  computed: {
    formDisabled() {
      return this.requestPending || !this.init;
    },
    newPasswordLabel() {
      return this.$globalTexts.login__reset_new_label || "";
    },
    confirmNewPasswordLabel() {
      return this.$globalTexts.login__reset_repeat_label || "";
    },
    resetPasswordButtonLabel() {
      return this.$globalTexts.login__reset_submit || "";
    },
    forgotPasswordPageUrl() {
      return this.$route.pathname + "?action=forgot-password";
    },
  },
  methods: {
    passwordFocused() {
      this.passwordError = "";
      this.resetFormSubmissionState();
    },
    passwordChanged(formSection) {
      this.password = formSection.password;
      this.passwordError = formSection.passwordError;
    },
    resetFormSubmissionState() {
      this.formSubmitted = false;
    },
    onEnterKeyPressed() {
      this.submitResetPasswordForm();
    },
    submitResetPasswordForm() {
      this.resetFormSubmissionState();
      this.$refs.confirmPassword.updateFormState();

      if (this.passwordError) {
        return;
      }

      this.$emit("reset-password", {
        password: this.password,
        resetPasswordCode: this.$route.query.code,
      });

      this.formSubmitted = true;
    },
    async navigateToForgotPasswordPage(code) {
      this.navigate(
        this.$route.pathname + `?action=forgot-password&code=${code}`
      );
    },
  },
  async created() {
    const resetPasswordCode = this.$route.query.code;
    if (!resetPasswordCode) {
      await this.navigateToForgotPasswordPage("-");
    } else if (!(await this.validateResetPasswordCode(resetPasswordCode))) {
      await this.navigateToForgotPasswordPage(resetPasswordCode);
    } else {
      this.init = true;
    }
  },
};
</script>

<template>
  <div class="reset-password-form">
    <div class="reset-password-form__wrapper" v-if="init">
      <confirm-password-form-section
        ref="confirmPassword"
        row-css-class="reset-password-form__row"
        row-label-css-class="reset-password-form__label"
        :password-label="newPasswordLabel"
        password-label-for="resetNewPassword"
        :confirm-password-label="confirmNewPasswordLabel"
        confirm-password-label-for="resetConfirmNewPasword"
        @input-focused="passwordFocused"
        @enter-key-pressed="onEnterKeyPressed"
        @password-changed="passwordChanged"
      />

      <div v-if="formSubmitted && error" class="reset-password-form__row">
        <div class="reset-password-form__error">
          {{ error }}
        </div>
      </div>

      <div class="reset-password-form__row">
        <button
          class="reset-password-form__button secondary-button"
          :disabled="formDisabled"
          @mousedown.prevent
          @click="
            $event.target.focus();
            submitResetPasswordForm();
          "
        >
          {{ resetPasswordButtonLabel }}
        </button>
      </div>
    </div>
    <div v-else class="reset-password-form__code-validation">
      {{ $globalTexts.login__reset_validating_reset_code }}
    </div>
  </div>
</template>

<style>
.reset-password-form {
  display: flex;
  justify-content: center;
}

.reset-password-form__wrapper {
  width: 100%;
}

.reset-password-form__row {
  margin-top: 1.5rem;
}

.reset-password-form__reset-password-page-link {
  margin-top: -0.5rem;
}

.reset-password-form__label {
  font-size: 18px;
  margin-bottom: 0.25rem;
}

.reset-password-form__button {
  width: 100%;
  margin-left: 0;
}

.reset-password-form__send-password-link {
  text-align: center;
  margin-top: 1rem;
}
.reset-password-form__code-validation {
  margin-top: 2rem;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
</style>
