<script>
import PageHeader from '../PageHeader.vue';
import LoginSection from './LoginFormLoginSection.vue';
import ForgotPasswordSection from './LoginFormForgotSection.vue';
import ResetPasswordSection from './LoginFormResetSection.vue';

const uiSteps = Object.freeze({
  Login: Symbol('Login'),
  ForgotPassword: Symbol('ForgotPassword'),
  ResetPassword: Symbol('ResetPassword'),
});

export default {
  components: {
    PageHeader,
    LoginSection,
    ForgotPasswordSection,
    ResetPasswordSection,
  },
  props: {
    requestPending: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
    },
  },
  data: () => ({
    uiSteps,
    uiStep: null,
  }),
  watch: {
    '$route.query': function () {
      this.setUiStep();
    },
  },
  methods: {
    setUiStep() {
      const { query } = this.$route;

      if (query.action === 'forgot-password') {
        this.uiStep = this.uiSteps.ForgotPassword;
      } else if (query.action === 'reset') {
        this.uiStep = this.uiSteps.ResetPassword;
      } else {
        this.uiStep = this.uiSteps.Login;
      }
    },
  },
  created() {
    this.setUiStep();
  },
};
</script>

<template>
  <div v-if="uiStep === uiSteps.Login">
    <page-header :title="$globalTexts.login__title" />
    <login-section
      :request-pending="requestPending"
      :error="error"
      @login="$emit('login', $event)"
    />
  </div>
  <div v-else-if="uiStep === uiSteps.ForgotPassword">
    <page-header :title="$globalTexts.login__forgot_title" />
    <forgot-password-section
      :request-pending="requestPending"
      :error="error"
      @forgot-password="$emit('forgot-password', $event)"
    />
  </div>
  <div v-else>
    <page-header :title="$globalTexts.login__reset_title" />
    <reset-password-section
      :request-pending="requestPending"
      :error="error"
      @reset-password="$emit('reset-password', $event)"
    />
  </div>
</template>
