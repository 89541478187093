<script>
import EmailFormSection from "./EmailFormSection";

export default {
  components: { EmailFormSection },
  props: {
    requestPending: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
    },
  },
  data: () => ({
    email: "",
    emailError: "",
    formSubmitted: false,
    submittedEmail: "",
    codeExpired: false,
  }),
  emits: ["forgot-password"],
  computed: {
    isFormDisabled() {
      return this.requestPending;
    },

    emailLabel() {
      return this.$globalTexts?.login__email_label || "";
    },
    forgotPasswordButtonLabel() {
      return this.$globalTexts?.login__forgot_submit || "";
    },
    loginPageUrl() {
      return this.$route.pathname;
    },
    formSubmitMessage() {
      return this.$replaceTokens(this.$globalTexts.login__forgot_success, {
        email: this.submittedEmail,
      });
    },
  },
  methods: {
    emailFocused() {
      this.emailError = "";
      this.resetFormSubmissionState();
    },
    emailChanged(formSection) {
      this.email = formSection.email;
      this.emailError = formSection.emailError;
    },
    resetFormSubmissionState() {
      this.formSubmitted = false;
    },
    onEnterKeyPressed() {
      this.onForgotPasswordFormSubmitted();
    },
    onForgotPasswordFormSubmitted() {
      this.resetFormSubmissionState();
      this.$refs.email.updateFormState();
      if (this.emailError) {
        return;
      }

      this.$emit("forgot-password", {
        email: this.email,
      });

      this.submittedEmail = this.email;
      this.formSubmitted = true;
    },
  },
  created() {
    const resetPasswordCode = this.$route.query.code;
    if (resetPasswordCode) {
      this.codeExpired = true;
    }
  },
};
</script>

<template>
  <div class="forgot-password-form">
    <div class="forgot-password-form__wrapper">
      <div v-if="codeExpired" class="forgot-password-form__code-expired">
        {{ $globalTexts.login__forgot_expired_reset_code }}
      </div>
      <email-form-section
        ref="email"
        row-css-class="forgot-password-form__row"
        row-label-css-class="forgot-password-form__label"
        :email-label="emailLabel"
        @input-focused="emailFocused"
        @email-changed="emailChanged"
        @enter-key-pressed="onEnterKeyPressed"
      />

      <div v-if="formSubmitted && error" class="forgot-password-form__row">
        <div class="forgot-password-form__error">
          {{ error }}
        </div>
      </div>

      <div
        v-if="formSubmitted && !error"
        class="forgot-password-form__submitted"
      >
        {{ formSubmitMessage }}.
      </div>

      <div class="forgot-password-form__row">
        <button
          class="primary-button forgot-password-form__button"
          :disabled="isFormDisabled"
          @click="onForgotPasswordFormSubmitted()"
        >
          {{ forgotPasswordButtonLabel }}
        </button>
      </div>

      <router-link
        :to="loginPageUrl"
        class="forgot-password-form__login-page-link"
      >
        {{ $globalTexts.login__forgot_login_link }}</router-link
      >
    </div>
  </div>
</template>

<style>
.forgot-password-form {
  display: flex;
  justify-content: center;
}

.forgot-password-form__code-expired {
  text-align: center;
  margin-top: 1.5rem;
}

.forgot-password-form__wrapper {
  width: 100%;
}

.forgot-password-form__row {
  margin-top: 1.5rem;
}

.forgot-password-form__label {
  font-size: 18px;
  margin-bottom: 0.25rem;
}

.forgot-password-form__submitted {
  font-size: 12px;
  text-align: center;
  color: #000000;
  line-height: 14px;
  letter-spacing: 0.18px;
  margin-top: 0.25rem;
}

.forgot-password-form__error {
  color: red;
  font-size: 12px;
  text-align: center;
  line-height: 14px;
  letter-spacing: 0.18px;
  margin-top: 0.25rem;
  color: var(--color-feedback-error);
  font-weight: 600;
}

.forgot-password-form__button {
  width: 100%;
  margin-left: 0;
}

.forgot-password-form__login-page-link {
  text-align: center;
  margin-top: 1rem;
  display: block;
}
</style>
