<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
  },
};
</script>

<template>
  <div class="page-header">
    <h4 class="page-header__title">
      {{ title }}
    </h4>
    <p v-if="description" class="page-header__description large">
      {{ description }}
    </p>
  </div>
</template>

<style>
.page-header__title,
.page-header__description {
  text-align: center;
  margin: 0;
}
</style>
