<script>
import TextInput from "../forms/TextInput.vue";

export default {
  components: { TextInput },
  props: {
    sectionCssClass: {
      type: String,
      default: "",
    },
    rowCssClass: {
      type: String,
      default: "",
    },
    passwordLabel: {
      type: String,
      required: true,
    },
    passwordLabelFor: {
      type: String,
      default: "password",
    },
    confirmPasswordLabel: {
      type: String,
      required: true,
    },
    confirmPasswordLabelFor: {
      type: String,
      default: "confirmPassword",
    },
  },
  data: () => ({
    password: "",
    passwordError: "",
    confirmPassword: "",
    confirmPasswordError: "",
    isConfirmPasswordPristine: true,
  }),
  computed: {
    passwordRegex() {
      return this.$globalFields?.PasswordPolicyRegex || "";
    },
    passwordPolicyValidationError() {
      return this.$globalTexts?.login__reset_too_simple || "";
    },
    requiredFieldError() {
      return this.$globalTexts?.global__forms__requiredfielderror || "";
    },
    passwordDoesntMatchError() {
      return this.$globalTexts?.login__reset_no_match || "";
    },
  },
  methods: {
    onPasswordFocused() {
      this.passwordError = "";
      this.confirmPasswordError = "";

      this.$emit("input-focused");
    },
    onPasswordChanged() {
      if (!this.password) {
        this.passwordError = this.requiredFieldError;
      } else if (!RegExp(this.passwordRegex).test(this.password)) {
        this.passwordError = this.passwordPolicyValidationError;
      } else {
        this.passwordError = "";
      }

      if (!this.isConfirmPasswordPristine) {
        this.onConfirmPasswordChanged();
      }

      this.emitPasswordChanged();
    },
    onConfirmPasswordFocused() {
      this.confirmPasswordError = "";
      this.$emit("input-focused");
    },
    onConfirmPasswordChanged() {
      if (!this.confirmPassword) {
        this.confirmPasswordError = this.requiredFieldError;
      } else if (this.confirmPassword !== this.password) {
        this.confirmPasswordError = this.passwordDoesntMatchError;
      } else {
        this.confirmPasswordError = "";
      }

      this.emitPasswordChanged();
    },
    setPristine() {
      this.onPasswordFocused();
    },
    updateFormState() {
      this.onPasswordChanged();
      this.onConfirmPasswordChanged();
    },
    emitPasswordChanged() {
      this.$emit("password-changed", {
        password: this.password,
        passwordError: this.passwordError || this.confirmPasswordError,
      });
    },
  },
};
</script>

<template>
  <div :class="[sectionCssClass]">
    <div :class="[rowCssClass]">
      <text-input
        :id="passwordLabelFor"
        :label="passwordLabel"
        v-model="password"
        type="password"
        :error-message="passwordError"
        @focus="onPasswordFocused"
        @blur="onPasswordChanged"
        @enter-key-pressed="$emit('enter-key-pressed')"
      />
    </div>

    <div :class="[rowCssClass]">
      <text-input
        :id="confirmPasswordLabelFor"
        :label="confirmPasswordLabel"
        v-model="confirmPassword"
        type="password"
        :error-message="confirmPasswordError"
        @focus="onConfirmPasswordFocused"
        @blur="onConfirmPasswordChanged"
        @enter-key-pressed="$emit('enter-key-pressed')"
      />
    </div>
  </div>
</template>
